module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://emsculpt-bremen.de","canonical":"https://emsculpt-bremen.de","site_name":"EMSCULPT-BREMEN","title":"EMSCULPT NEO BREMEN","titleTemplate":"EMSCULPT NEO Bremen| %s","description":"Gesund, Fit und Beweglich mit EMSCULPT NEO in jedem Alter, Abbau des Fettgewebe (auch das gesundheitsschädliche viszerale Fett) und effektiven Muskelaufbau.","language":"de","openGraph":{"type":"website","site_name":"EMSCULPT-BREMEN","locale":"de_DE","url":"https://emsculpt-bremen.de","title":"EMSCULPT NEO BREMEN","description":"Gesund, Fit und Beweglich mit EMSCULPT NEO in jedem Alter, Abbau des Fettgewebe (auch das gesundheitsschädliche viszerale Fett) und effektiven Muskelaufbau.","images":[{"url":"https://emsculpt-bremen.de/default.jpg","width":800,"height":600,"alt":"Og Image Alt"}]},"handle":"@EstetieseBremen","site":"@EstetieseBremen","cardType":"summary_large_image"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EMSCULPT NEO in BREMEN in der Privatpraxis ESTETIESE","short_name":"EMSCu-NEOTHB","description":"EMSCULPT NEO Bremen : Gesund, Fit und Beweglich in jedem Alter durch Fettab- und Muskelaufbau. Emsculpt NEO® ermöglicht in verschiedene Körperzonen die Muskeln wirksam aufzubauen und das Fettgewebe (auch das gesundheitsschädliche viszerale Fett) effektiv abzubauen.","start_url":"/","lang":"de","display":"standalone","icon":"static/favicons/favicon.png","background_color":"#666","theme_color":"#fff","icons":[{"src":"static/favicons/favicon_512px.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_256px.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192px.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_127px.png","sizes":"127x127","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_96px.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_48px.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192_mascable.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2eec0a731cebda33410a86ba7cf387f9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
